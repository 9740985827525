<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
      <v-row justify="center" class="mb-8">
        <v-col class="px-0" cols="12">
          <v-card
            elevation="0"
            class="px-6 py-6"
            outline
            style="border-radius: 15px"
          >
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Nombre completo </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.full_name }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Cargo</i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.cargo }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Centro de costos </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.centro_costo }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Subcentro de costos </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.subcentro_costo }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Campaña</i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.campania }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Fecha de creación </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.created_at }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Fecha estimada de entrega </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.fecha_estimada_req }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Prioridad </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ obtenerNombrePorId(info.prioridad_id) }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Tipo de Solicitud </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ obtenerNombrePorId2(info.solicitud_id) }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Asignada a </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{info.user_id_soporte}}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" v-if="info.user_id_copia!=''">
              <p class="mb-1 candidate-details__data_title">
                <i> Usuarios en copia  </i>
              </p>
              <p class="text-left mb-1 candidate-details__data_txt">
                {{ info.user_id_copia }}
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="mb-1 candidate-details__data_title">
                <i> Desarrollo </i>
              </p>
              <p
                class="text-left mb-1 candidate-details__data_txt"
                v-html="info.mensaje"
              ></p>
              <v-divider></v-divider>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      listDescriptions: [],
      form: {
        status: null,
        description: "",
      },
      info: {},
      search: "",
      priority: [],
      typeRequest: [],
    };
  },
  props: ["dataProcess"],
  methods: {
    getNameStatus(status) {
      switch (status) {
        case 1:
          return "Citado";
        case 8:
          return "En proceso";
        case 4:
          return "Finalizado";
        default:
          return "Desconocido";
      }
    },
    searchData() {
      this.info = this.dataProcess;
    },
    getPriority() {
      Api.newDevelopment()
        .getPriority()
        .then((resp) => resp.json())
        .then((data) => {
          this.priority = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },
    getRequestType() {
      Api.newDevelopment()
        .getRequestType()
        .then((resp) => resp.json())
        .then((data) => {
          this.typeRequest = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },
    obtenerNombrePorId(id) {
      const objetoEncontrado = this.priority.find((objeto) => objeto.id === id.toString());
      return objetoEncontrado ? objetoEncontrado.name : null;
    },
    obtenerNombrePorId2(id) {
      const objetoEncontrado = this.typeRequest.find((objeto) => objeto.id === id.toString());
      return objetoEncontrado ? objetoEncontrado.name : null;
    },
  },
  mounted() {},
  created() {
    this.searchData();
    this.getPriority();
    this.getRequestType();
  },
};
</script>
<style>
.candidate-details__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-details__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}

.candidate-details__table {
  height: 420px;
  overflow-y: scroll;
}
</style>
