<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
      <v-row justify="center" class="mb-8">
        <v-col class="px-0" cols="12">
          <v-card
            elevation="0"
            class="px-6 py-6 history-text"
            outline
            style="border-radius: 15px"
          >
            <template v-if="dataHistorys[0] !== undefined">
              <v-col
                cols="12"
                v-if="dataHistorys[0].mensaje != data.mensaje"
                v-for="(data, index) in dataHistorys[0].history.slice(0, -1)"
                :key="index"
              >
                <p class="mb-1 candidate-details__data_title">
                  <i> {{ data.full_name }} {{ data.created_at }}</i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  Mensaje: {{ data.mensaje }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" v-else>
                <p class="mb-1 candidate-details__data_title">
                  <i> {{ data.full_name }} {{ data.created_at }}</i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  Cambio de prioridad a :
                  <span style="color: blue">
                    {{ obtenerNombrePorId(data.prioridad_id) }}
                  </span>
                </p>
                <v-divider></v-divider>
              </v-col>
            </template>
          </v-card>
          <v-col cols="12" class="mt-5" v-if="showInfo">
            <v-card
              elevation="0"
              class="px-6 py-6 feed"
              outline
              style="border-radius: 15px"
            >
              <span v-if="show"> Cambiar prioridad </span>
              <v-select
                prepend-inner-icon="mdi-priority-high"
                color="#466be3"
                :items="priority"
                v-model="priorityId"
                item-text="name"
                item-value="id"
                label="Prioridad"
                v-if="show"
              >
              </v-select>
              <v-textarea
                v-model="feedbackTxt"
                label="Escribe aquí tu Feedback"
              ></v-textarea>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="sendFeedback"
                  block
                  rounded
                  style="color: #fff"
                  :style="{
                    'background-color': hover ? '#324CA1' : '#466BE3',
                  }"
                  class="pa-xl-0 mt-10"
                >
                  Enviar
                </v-btn>
              </v-hover>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      listDescriptions: [],
      form: {
        status: null,
        description: "",
      },
      info: {},
      search: "",
      priority: [],
      typeRequest: [],
      feedbackTxt: "",
      priorityId: "",
    };
  },
  props: ["dataProcess", "dataHistorys", "show","showInfo"],
  methods: {
    getNameStatus(status) {
      switch (status) {
        case 1:
          return "Citado";
        case 8:
          return "En proceso";
        case 4:
          return "Finalizado";
        default:
          return "Desconocido";
      }
    },
    searchData() {
      this.info = this.dataProcess;
    },
    getPriority() {
      Api.newDevelopment()
        .getPriority()
        .then((resp) => resp.json())
        .then((data) => {
          this.priority = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },
    getRequestType() {
      Api.newDevelopment()
        .getRequestType()
        .then((resp) => resp.json())
        .then((data) => {
          this.typeRequest = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },
    sendFeedback() {
      const feedbackData = {
        feedbackTxt: this.feedbackTxt,
        priorityId: this.priorityId,
      };
      this.$emit("dataFeedback", feedbackData);
    },
    obtenerNombrePorId(id) {
      const objetoEncontrado = this.priority.find(
        (objeto) => objeto.id === id.toString()
      );
      return objetoEncontrado ? objetoEncontrado.name : null;
    },
  },
  watch: {
    dataHistorys(newVal, oldVal) {
      console.log(newVal);
    },
  },
  mounted() {},

  created() {
    this.searchData();
    this.getPriority();
    this.getRequestType();
  },
};
</script>
<style>
.history-text {
  max-height: 400px;
  overflow: auto;
  border-radius: 15px;
}
.feed {
  background-color: #f6f9ff !important;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.candidate-details__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-details__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}

.candidate-details__table {
  height: 420px;
  overflow-y: scroll;
}
</style>
